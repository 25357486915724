<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem v-if="type === 'upload'">上传协议</BreadcrumbItem>
        <BreadcrumbItem v-if="type === 'modify'">修改协议</BreadcrumbItem>
        <BreadcrumbItem v-if="type !== 'upload' && type !== 'modify'">
          发起协议
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <a-form class="advanced-search-form" :form="form">
        <a-row>
          <a-col :span="9">
            <a-form-item label="委托机构（甲方）：">
              电行世界
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="受托机构（乙方）：">
              <span v-if="type === 'modify' || type === 'upload'">
                {{ info.partBsInfo ? info.partBsInfo.partyName : "-" }}
              </span>
              <a-select
                v-else
                v-decorator="[
                  'partyBsId',
                  {
                    rules: [
                      { required: true, message: '请选择受托机构（乙方）' }
                    ]
                  }
                ]"
                show-search
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in partyB"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="受托机构（丙方）：">
              <span v-if="type === 'modify' || type === 'upload'">
                {{ info.partCsInfo ? info.partCsInfo.partyName : "-" }}
              </span>
              <a-select
                v-else
                v-decorator="[
                  'partyCsId',
                  {
                    rules: [
                      { required: true, message: '请选择受托机构（丙方）' }
                    ]
                  }
                ]"
                show-search
                :filter-option="filterOption"
                @change="onChangePartyC"
              >
                <a-select-option
                  v-for="item in partyC"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="管辖区域">
              <div v-if="type === 'modify' || type === 'upload'">
                {{
                  partyCInfo.corporationJurisdiction
                    ? partyCInfo.corporationJurisdiction
                    : "-"
                }}
              </div>
              <div v-else>
                {{
                  partyCInfo.corporationJurisdiction
                    ? partyCInfo.corporationJurisdiction
                    : "-"
                }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="协议签订/解除">
              <span v-if="type === 'modify' || type === 'upload'">
                {{
                  info.contractType === "三方合作协议解除"
                    ? "协议解除"
                    : "协议签订"
                }}
              </span>
              <a-radio-group
                v-else
                v-decorator="[
                  'contractType',
                  { rules: [{ required: true, message: '请选择' }] }
                ]"
              >
                <a-radio value="TRIPARTITE_COOPERATION_AGREEMENT_SIGN">
                  签订
                </a-radio>
                <a-radio value="TRIPARTITE_COOPERATION_AGREEMENT_TERMINATE">
                  解除
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="资金规模" class="form__item">
              <a-input-number
                v-decorator="[
                  'capitalScale',
                  { rules: [{ required: true, message: '请填写资金规模' }] }
                ]"
                :min="0"
                class="input__number"
              />
              元
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="type === 'modify'">
          <a-col :span="9">
            <a-form-item label="审核状态">
              {{ info.auditStatus ? info.auditStatus : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="审核意见">
              <a @click="commentVisible = true">查看</a>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="三方协议" class="required">
              <a-upload
                v-if="type === 'modify' || type === 'upload'"
                name="file"
                accept=".pdf,.doc,.docx"
                :multiple="false"
                :action="importUrl"
                :headers="headers"
                :file-list="defaultFileList"
                :before-upload="
                  file => beforeUploadFile(file, 'defaultFileList')
                "
                @change="handleChangeDefaultFile"
              >
                <a-button v-if="defaultFileList.length < 1">
                  <a-icon type="upload" />
                  上传文件
                </a-button>
              </a-upload>
              <a-upload
                v-else
                name="file"
                accept=".doc,.docx"
                :multiple="false"
                :action="importUrl"
                :headers="headers"
                :file-list="fileList"
                :before-upload="file => beforeUploadFile(file, 'fileList')"
                @change="handleChangeFile"
              >
                <a-button v-if="fileList.length < 1">
                  <a-icon type="upload" />
                  上传文件
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item label="合作起止时间">
              <a-range-picker
                v-decorator="[
                  'time',
                  { rules: [{ required: true, message: '请选择合作起止时间' }] }
                ]"
                :disabled-date="disabledDate"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="18">
            <a-divider />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="9">
            <a-form-item label="甲方审核状态">
              <a-radio-group default-value="同意" disabled>
                <a-radio value="同意">同意</a-radio>
                <a-radio value="不同意">不同意</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="18">
            <a-form-item label="审核意见">
              <a-textarea
                v-decorator="['partyAsReviewComments']"
                class="examine__memo"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="18">
            <a-form-item label="乙方审核状态">待审核</a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="审核意见">--</a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="18">
            <a-form-item label="丙方审核状态">待审核</a-form-item>
          </a-col>
          <a-col :span="18">
            <a-form-item label="审核意见">--</a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-space class="operation">
        <a-button v-if="type === 'modify'" type="primary" @click="onModify">
          提交
        </a-button>
        <a-button v-if="type === 'upload'" type="primary" @click="onUpload">
          提交
        </a-button>
        <a-button
          v-if="type !== 'modify' && type !== 'upload'"
          type="primary"
          @click="onSubmit"
        >
          提交
        </a-button>
        <a-button @click="onCancel">取消</a-button>
      </a-space>
    </div>

    <!--  签署意见弹窗  -->
    <a-modal
      :visible="commentVisible"
      title="审核意见"
      :footer="null"
      width="50%"
      @cancel="commentVisible = false"
    >
      <a-collapse default-active-key="0">
        <a-collapse-panel
          v-for="(item, index) in historyInfo"
          :key="index"
          :header="item.contractName"
        >
          <div slot="extra">
            发起时间：{{ tsFormat(item.contractCreateTime) }}
          </div>
          <div class="corporation__name">
            {{
              item.approveInfoList && item.approveInfoList.length > 0
                ? item.approveInfoList[0].partyName
                : "-"
            }}
          </div>
          <div class="status">
            <div>
              审核状态：{{
                item.approveInfoList && item.approveInfoList.length > 0
                  ? item.approveInfoList[0].partyApproveStatus
                  : "-"
              }}
            </div>
            <div>
              签署状态：{{
                item.approveInfoList && item.approveInfoList.length > 0
                  ? item.approveInfoList[0].partySignStatus
                  : "-"
              }}
            </div>
          </div>
          <div
            v-if="
              item.approveInfoList &&
                item.approveInfoList.length > 0 &&
                item.approveInfoList[0].partyReviewComments
            "
            class="memo"
          >
            <div style="min-width: 72px">审核意见：</div>
            <div>
              {{ item.approveInfoList[0].partyReviewComments }}
            </div>
          </div>
          <div class="block"></div>
          <div class="corporation__name">
            {{
              item.approveInfoList && item.approveInfoList.length > 0
                ? item.approveInfoList[1].partyName
                : "-"
            }}
          </div>
          <div class="status">
            <div>
              审核状态：{{
                item.approveInfoList && item.approveInfoList.length > 0
                  ? item.approveInfoList[1].partyApproveStatus
                  : "-"
              }}
            </div>
            <div>
              签署状态：{{
                item.approveInfoList && item.approveInfoList.length > 0
                  ? item.approveInfoList[1].partySignStatus
                  : "-"
              }}
            </div>
          </div>
          <div
            v-if="
              item.approveInfoList &&
                item.approveInfoList.length > 0 &&
                item.approveInfoList[1].partyReviewComments
            "
            class="memo"
          >
            <div style="min-width: 72px">审核意见：</div>
            <div>
              {{ item.approveInfoList[1].partyReviewComments }}
            </div>
          </div>
          <div class="block"></div>
          <div class="corporation__name">
            {{
              item.approveInfoList && item.approveInfoList.length > 0
                ? item.approveInfoList[2].partyName
                : "-"
            }}
          </div>
          <div class="status">
            <div>
              审核状态：{{
                item.approveInfoList && item.approveInfoList.length > 0
                  ? item.approveInfoList[2].partyApproveStatus
                  : "-"
              }}
            </div>
            <div>
              签署状态：{{
                item.approveInfoList && item.approveInfoList.length > 0
                  ? item.approveInfoList[2].partySignStatus
                  : "-"
              }}
            </div>
          </div>
          <div
            v-if="
              item.approveInfoList &&
                item.approveInfoList.length > 0 &&
                item.approveInfoList[2].partyReviewComments
            "
            class="memo"
          >
            <div style="min-width: 72px">审核意见：</div>
            <div>
              {{ item.approveInfoList[2].partyReviewComments }}
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-modal>
  </a-layout-content>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import { buildHeaders } from "@/components/utils";
import {
  fetchSignCorporation,
  fetchTripartiteAgreementDetail,
  fetchTripartiteAgreementHistory,
  initiateAgreement,
  modifyTripartiteAgreement,
  uploadTripartiteAgreement
} from "@/services/AgreementService";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import moment from "moment";
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { upload } from "@/services/UploadService";

export default {
  name: "InitiateAgreement",
  components: {
    BreadcrumbItem,
    Breadcrumb
  },
  data() {
    return {
      form: this.$form.createForm(this),
      importUrl: `${baseURL}/upload-word/cooperation_agreement`,
      headers: buildHeaders(),
      fileList: [],
      partyB: [], // 乙方下拉列表
      partyC: [], // 丙方下拉列表
      partyBList: [], // 所有乙方-货栈
      partyCList: [], // 所有丙方-服务中心
      type: this.$route.params.type,
      contractNo: this.$route.params.contractNo,
      info: {},
      commentVisible: false,
      historyInfo: [],
      defaultFileList: [],
      partyCInfo: {},
      tsFormat,
      fromRouterName: "",
      storeRouterName: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    this.loadSecondPartyList();
    this.loadThirdPartyList();
    if (this.type === "modify" || this.type === "upload") {
      this.loadDetail();
    }
  },
  methods: {
    // 取消
    onCancel() {
      this.$router.go(-1);
    },
    // 上传文件
    handleChangeFile({ fileList }) {
      this.fileList = fileList.filter(item => item.url);
    },
    // 改变默认文件
    handleChangeDefaultFile({ fileList }) {
      this.defaultFileList = fileList.filter(item => item.url);
    },
    beforeUploadFile(file, fileListName) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("文件名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("文件大小超过20MB");
        } else {
          this.uploadFile("cooperation_agreement", file, fileListName);
        }
      }
      return false;
    },
    async uploadFile(type, file, fileListName) {
      const { data = {} } = await upload(type, file, "pdf");
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        let list = [];
        list.push({
          uid: list.length,
          name: file.name,
          fileName: file.name,
          url: imageBaseUrl + data.data,
          fileUrl: data.data,
          response: data
        });
        this[fileListName] = [...list];
      }
    },
    // 查询乙方（货栈）
    loadSecondPartyList() {
      const params = {
        corporationName: "",
        distributorType: "warehouse"
      };
      fetchSignCorporation(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.partyBList = resp.data.data;
          this.partyB = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 查询丙方（服务中心）
    loadThirdPartyList() {
      const params = {
        corporationName: "",
        distributorType: "serviceCenter"
      };
      fetchSignCorporation(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.partyCList = resp.data.data;
          this.partyC = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 改变乙方
    // onChangePartyB(value) {
    //   if (this.form.getFieldsValue().partyCsId) {
    //     this.form.setFieldsValue({ partyCsId: "" });
    //     this.partyC = [];
    //   } else {
    //     if (value) {
    //       const obj = this.partyBList.find(
    //         item => item.corporationId === value
    //       );
    //       this.partyC = obj.bindDataVOList;
    //     } else {
    //       this.partyB = [...this.partyBList];
    //       this.partyC = [...this.partyCList];
    //     }
    //   }
    // },
    // 改变丙方
    onChangePartyC(value) {
      this.partyCInfo = this.partyC.find(item => value === item.corporationId);
    },
    // 提交
    onSubmit() {
      if (this.fileList.length === 0) {
        this.$message.info("请上传三方协议");
        return;
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...this.form.getFieldsValue(),
            contractStartTime:
              values.time && values.time.length > 0
                ? dtFormat(values.time[0])
                : "",
            contractEndTime:
              values.time && values.time.length > 0
                ? dtFormat(values.time[1])
                : "",
            fileForm: this.fileList[0]
          };
          initiateAgreement(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("提交成功");
              if (this.storeRouterName === "ToDoTask") {
                this.$router.replace({
                  name: "TripartiteAgreementDetail",
                  params: {
                    contractNo: this.contractNo
                  }
                });
              } else {
                this.$router.push({ name: "TripartiteAgreementManage" });
              }
            }
          });
        }
      });
    },
    // 加载详情
    loadDetail() {
      fetchTripartiteAgreementDetail(this.contractNo).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          this.partyCInfo = resp.data.data.partCsInfo;
          this.form.setFieldsValue({
            capitalScale: this.info.capitalScale
          });
          if (this.info.fileForm) {
            this.defaultFileList.push({
              uid: 0,
              name: this.info.fileForm.fileName,
              status: "done",
              url: imageBaseUrl + this.info.fileForm.fileUrl,
              fileUrl: this.info.fileForm.fileUrl,
              fileName: this.info.fileForm.fileName
            });
          } else {
            this.defaultFileList = [];
          }
          if (this.info.contractStartTime && this.info.contractEndTime) {
            this.form.setFieldsValue({
              time: [
                tsFormat(this.info.contractStartTime),
                tsFormat(this.info.contractEndTime)
              ]
            });
          }
          this.loadAgreementHistory();
        }
      });
    },
    // 加载协议历史记录
    loadAgreementHistory() {
      const params = {
        serviceCenterId: this.info.partCsInfo
          ? this.info.partCsInfo.corporationId
          : "",
        warehouseId: this.info.partBsInfo
          ? this.info.partBsInfo.corporationId
          : ""
      };
      fetchTripartiteAgreementHistory(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            this.historyInfo = data;
          }
        }
      });
    },
    // 上传协议
    onUpload() {
      if (this.defaultFileList.length === 0) {
        this.$message.info("请上传三方协议");
        return;
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          const fileForm = {
            fileUrl: this.defaultFileList[0].fileUrl
              ? this.defaultFileList[0].fileUrl
              : this.defaultFileList[0].response.data,
            fileName: this.defaultFileList[0].fileName
              ? this.defaultFileList[0].fileName
              : this.defaultFileList[0].name
          };
          const params = {
            contractStartTime:
              values.time && values.time.length > 0
                ? dtFormat(values.time[0])
                : "",
            contractEndTime:
              values.time && values.time.length > 0
                ? dtFormat(values.time[1])
                : "",
            capitalScale: this.form.getFieldsValue().capitalScale
              ? this.form.getFieldsValue().capitalScale
              : "",
            fileForm: fileForm,
            partyAsReviewComments: this.form.getFieldsValue()
              .partyAsReviewComments
              ? this.form.getFieldsValue().partyAsReviewComments
              : ""
          };
          uploadTripartiteAgreement(this.contractNo, params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("提交成功");
              if (this.storeRouterName === "ToDoTask") {
                this.$router.replace({
                  name: "TripartiteAgreementDetail",
                  params: {
                    contractNo: this.contractNo
                  }
                });
              } else {
                this.$router.push({ name: "TripartiteAgreementManage" });
              }
            }
          });
        }
      });
    },
    // 修改
    onModify() {
      if (this.defaultFileList.length === 0) {
        this.$message.info("请上传三方协议");
        return;
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          const fileForm = {
            fileUrl: this.defaultFileList[0].fileUrl
              ? this.defaultFileList[0].fileUrl
              : this.defaultFileList[0].response.data,
            fileName: this.defaultFileList[0].fileName
              ? this.defaultFileList[0].fileName
              : this.defaultFileList[0].name
          };
          const params = {
            contractStartTime:
              values.time && values.time.length > 0
                ? dtFormat(values.time[0])
                : "",
            contractEndTime:
              values.time && values.time.length > 0
                ? dtFormat(values.time[1])
                : "",
            capitalScale: this.form.getFieldsValue().capitalScale
              ? this.form.getFieldsValue().capitalScale
              : "",
            fileForm: fileForm,
            partyAsReviewComments: this.form.getFieldsValue()
              .partyAsReviewComments
              ? this.form.getFieldsValue().partyAsReviewComments
              : ""
          };
          modifyTripartiteAgreement(this.contractNo, params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("提交成功");
              if (this.storeRouterName === "ToDoTask") {
                this.$router.replace({
                  name: "TripartiteAgreementDetail",
                  params: {
                    contractNo: this.contractNo
                  }
                });
              } else {
                this.$router.push({ name: "TripartiteAgreementManage" });
              }
            }
          });
        }
      });
    },
    // 时间禁用
    disabledDate(current) {
      return current < moment();
    },
    // 筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 100vh;
  background-color: white;
  margin: 30px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 138px !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
}

/deep/ .ant-form-item {
  margin-right: 40px;
}

.input__number {
  width: calc(100% - 30px);
}

.operation {
  margin-left: 138px;
}

.required /deep/ .ant-form-item-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.corporation__name {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
}

.status {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.block {
  height: 25px;
}

/deep/ .ant-upload-list-item-name {
  width: 200px;
}

.examine__memo {
  width: 100%;
}

.memo {
  display: flex;
}
</style>
